// vendors
import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Link, List, ListItem } from './LangSwitcher.styles';

const LangSwitcher = ({ localeLinks }) => {
  const { locale } = useIntl();

  const enObj = localeLinks.find(({ langKey }) => langKey === 'en');
  const frObj = localeLinks.find(({ langKey }) => langKey === 'fr');

  return (
    <List>
      <ListItem>
        <Link
          to={enObj ? enObj.href : ''}
          disabled={locale === 'en' && 'disabled'}
        >
          <abbr>en</abbr>
        </Link>
      </ListItem>

      <ListItem>
        <Link
          to={frObj ? frObj.href : ''}
          disabled={locale === 'fr' && 'disabled'}
        >
          <abbr>fr</abbr>
        </Link>
      </ListItem>
    </List>
  );
};

LangSwitcher.propTypes = {
  localeLinks: PropTypes.arrayOf(
    PropTypes.shape({
      langKey: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
};

LangSwitcher.defaultProps = {
  localeLinks: [],
};

export default LangSwitcher;

import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import colors from '@src/styles/colors';

import { List as SubList } from './SubNavigation/SubNavigation.styles';

export const Wrapper = styled.nav`
  display: grid;
  grid-gap: 1em;
`;

export const List = styled.ul`
  display: grid;
  grid-auto-flow: inherit;
  grid-gap: inherit;
  margin: 0;
`;

export const Link = styled(GatsbyLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  color: inherit;
  font-weight: 600;
  font-size: 1em;

  text-decoration: none;
  word-break: normal;

  &.active {
    color: ${colors.turtleGreen};
  }
`;

export const ListItem = styled.li`
  position: relative;

  :hover,
  :focus {
    > ${Link} {
      color: ${colors.turtleGreen};
    }

    > ${SubList} {
      opacity: 1;

      pointer-events: auto;
    }
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

import styled from 'styled-components';
import { em, rem } from 'polished';
import { Link as GatsbyLink } from 'gatsby';

import { lessThan } from '@utils/mediaQuery';
import breakpointsRange from '@utils/breakpointsRange';

export const layoutBreakpoint = 1066;

export const Container = styled.footer`
  min-height: 435px;
  padding: ${rem(60, 22)} 24px ${rem(82, 22)};

  background-color: ${({ theme }) => theme.colors.black};
`;

export const Wrapper = styled.div`
  max-width: 1408px;
  margin: auto;
`;

export const FeaturedNavigation = styled.nav`
  ${({ theme }) => theme.layouts.switcher(3, rem(80, 22), em(832))}
`;

export const Title = styled.h3`
  margin-bottom: ${rem(5, 22)};

  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  line-height: ${26 / 18};

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [20, 18],
        bases: [16, 22],
        unit: 'rem',
      },
    ],
    [480, 1066]
  )};
`;

export const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.body};
  line-height: ${26 / 16};
  text-decoration: none;

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [16, 16],
        bases: [16, 22],
        unit: 'rem',
      },
    ],
    [480, 1066]
  )};

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const ContactBlock = styled.div``;

export const Contact = styled.address`
  margin-top: ${rem(20, 22)};
`;

export const Phone = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${rem(34, 22)};
  font-style: normal;
  line-height: ${46 / 34};
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const SocialContainer = styled.div`
  display: flex;

  margin-top: ${rem(30, 22)};

  ${lessThan(layoutBreakpoint)} {
    justify-content: center;
  }

  a {
    display: inline-block;
    width: ${em(31, 22)};

    :not(:last-child) {
      margin-right: ${em(20, 22)};
    }
  }
`;

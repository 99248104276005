// vendors
import React from 'react';
import { hideVisually, rem } from 'polished';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

// utils
import { lessThan } from '@utils/mediaQuery';

// images
import IconFacebook from '@images/IconFacebook';
import IconYoutube from '@images/IconYoutube';
import IconVimeo from '@images/IconVimeo';

// styles
import {
  layoutBreakpoint,
  Container,
  Wrapper,
  FeaturedNavigation,
  Link,
  Phone,
  Title,
  Contact,
  SocialContainer,
} from './Footer.styles';

const messages = defineMessages({
  productsServicesForBusinessesPath: {
    defaultMessage: '/produits/produits-secteur-construction',
    description:
      'Path to the Products and services for businesses page from the footer section',
  },
  storeDisplaysPath: {
    defaultMessage: '/produits/presentoirs-commerciaux-marchandisage',
    description: 'Path to the Store displays page from the footer section',
  },
  storageEmergencyServicesPath: {
    defaultMessage: '/produits/rangement-services-urgence',
    description:
      'Path to the Storage for Emergency services page from the footer section',
  },
  wireShelvingPath: {
    defaultMessage: '/produits/tablettes-grillagees-particuliers',
    description: 'Path to the Wire shelving page from the footer section',
  },
  storageAccessoriesPath: {
    defaultMessage: '/produits/accessoires-rangement',
    description: 'Path to the Storage accessories page from the footer section',
  },
  installationGuidePath: {
    defaultMessage: '/particuliers#guide-installation',
    description:
      'Path to the Installation guide section in consumers page from the footer section',
  },
  projectsPath: {
    defaultMessage: '/realisations',
    description: 'Path to the Projects page from the footer section',
  },
  contactPath: {
    defaultMessage: '/nous-contacter',
    description: 'Path to the Contact page from the footer section',
  },
  careersPath: {
    defaultMessage: '/nous-contacter#carrieres',
    description: 'Path to the Careers page from the footer section',
  },
  protectPrivateInfoPath: {
    defaultMessage: '/protection-renseignements-prives',
    description:
      'Path to the Protection of private information page from the footer section',
  },
});

const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Wrapper>
        <h2 css={hideVisually}>
          <FormattedMessage
            defaultMessage='Contacts et liens de navigation en vedette'
            description='Title of the footer section'
          />
        </h2>

        <FeaturedNavigation>
          <ul
            css={`
              ${lessThan(layoutBreakpoint)} {
                text-align: center;
              }
            `}
          >
            <li
              css={`
                ${({ theme }) => theme.layouts.stack(rem(30, 22))}
              `}
            >
              <div>
                <Title>
                  <FormattedMessage
                    defaultMessage='Produits et services pour les entreprises'
                    description='Navigation title in the footer section'
                  />
                </Title>

                <ul>
                  <li>
                    <Link
                      to={formatMessage(
                        messages.productsServicesForBusinessesPath
                      )}
                    >
                      <FormattedMessage
                        defaultMessage='Produits et services de construction'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </li>

                  <li>
                    <Link to={formatMessage(messages.storeDisplaysPath)}>
                      <FormattedMessage
                        defaultMessage='Présentoirs et équipements commerciaux'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={formatMessage(messages.storageEmergencyServicesPath)}
                    >
                      <FormattedMessage
                        defaultMessage='Produits secteur interventions d’urgences'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <Title>
                  <FormattedMessage
                    defaultMessage='Produits pour les particuliers'
                    description='Navigation title in the footer section'
                  />
                </Title>

                <ul>
                  <li>
                    <Link to={formatMessage(messages.wireShelvingPath)}>
                      <FormattedMessage
                        defaultMessage='Tablettes'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </li>

                  <li>
                    <Link to={formatMessage(messages.storageAccessoriesPath)}>
                      <FormattedMessage
                        defaultMessage='Rangements'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <Title>
                <FormattedMessage
                  defaultMessage='Réalisations'
                  description='Navigation title in the footer section'
                />
              </Title>

              <Link to={formatMessage(messages.projectsPath)}>
                <FormattedMessage
                  defaultMessage='Tous les projets'
                  description='Navigation link in the footer section'
                />
              </Link>
            </li>

            <li
              css={`
                ${lessThan(layoutBreakpoint)} {
                  order: -1;
                }
              `}
            >
              <Title>
                <FormattedMessage
                  defaultMessage='Contactez-nous'
                  description='Navigation title in the footer section'
                />
              </Title>

              <ul>
                <li>
                  <div>
                    <Link to={formatMessage(messages.contactPath)}>
                      <FormattedMessage
                        defaultMessage="Plus d'informations"
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </div>
                  <div>
                    <Link to={formatMessage(messages.protectPrivateInfoPath)}>
                      <FormattedMessage
                        defaultMessage='Protection des renseignements privés'
                        description='Navigation link in the footer section'
                      />
                    </Link>
                  </div>
                </li>
              </ul>

              <Contact>
                <Phone href='tel:+18004631434'>
                  <span css={hideVisually}>
                    <FormattedMessage
                      defaultMessage='Numéro de téléphone'
                      description='Contact phone number in the footer section'
                    />
                  </span>
                  1 800 463-1434
                </Phone>
              </Contact>

              <SocialContainer>
                <a
                  href='https://www.facebook.com/permafil'
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='facebook'
                >
                  <IconFacebook />
                </a>

                <a
                  href='https://www.youtube.com/user/Permafil'
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='youtube'
                >
                  <IconYoutube />
                </a>

                <a
                  href='https://vimeo.com/user70209770'
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='vimeo'
                >
                  <IconVimeo />
                </a>
              </SocialContainer>
            </li>
          </ul>
        </FeaturedNavigation>
      </Wrapper>
    </Container>
  );
};

export default Footer;

// vendors
import { css, createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

import { greaterThan, lessThan } from '@utils/mediaQuery';

export const rootStyles = css`
  /* Fix Safari bug with viewport units in calc() */
  min-height: 0vh;

  overflow-x: hidden;

  color: ${({ theme }) => theme.styles.root.color};
  font-weight: ${({ theme }) => theme.styles.root.fontWeight};

  /*  A = (22 - 16) / (1568 - 320)
      B = 22 - (((22 - 16) / (1568 - 320)) * 1568) - 1rem{16}
      calc(1rem + Avw + b) */
  font-size: calc(1rem + 0.48076923vw - 1.538461538px);
  font-family: ${({ theme }) => theme.styles.root.fontFamily};
  line-height: ${({ theme }) => theme.styles.root.lineHeight};

  ${lessThan(320)} {
    font-size: ${rem(16)};
  }

  ${greaterThan(1568)} {
    font-size: ${rem(22)};
  }
`;

export const htmlStyles = css`
  /* Background Color */
`;

export const bodyStyles = css`
  /* fonts Styling */
`;

export const h1Style = css`
  font-weight: ${({ theme }) => theme.styles.h1.fontWeight};
  font-size: ${({ theme }) => theme.styles.h1.fontSize[0]}rem;
  line-height: ${({ theme }) => theme.styles.h1.lineHeight[0]};

  ${greaterThan(480)} {
    font-size: ${({ theme }) => theme.styles.h1.fontSize[1]}rem;
    line-height: ${({ theme }) => theme.styles.h1.lineHeight[1]};
  }
`;

export const h2Style = css`
  font-weight: ${({ theme }) => theme.styles.h2.fontWeight};
  font-size: ${({ theme }) => theme.styles.h2.fontSize[0]}rem;
  line-height: ${({ theme }) => theme.styles.h2.lineHeight[0]};

  ${greaterThan(480)} {
    font-size: ${({ theme }) => theme.styles.h2.fontSize[1]}rem;
    line-height: ${({ theme }) => theme.styles.h2.lineHeight[1]};
  }
`;

export const h3Style = css`
  font-weight: ${({ theme }) => theme.styles.h3.fontWeight};
  font-size: ${({ theme }) => theme.styles.h3.fontSize[0]}rem;
  line-height: ${({ theme }) => theme.styles.h3.lineHeight[0]};

  ${greaterThan(480)} {
    font-size: ${({ theme }) => theme.styles.h3.fontSize[1]}rem;
    line-height: ${({ theme }) => theme.styles.h3.lineHeight[1]};
  }
`;

export const h4Style = css`
  font-weight: ${({ theme }) => theme.styles.h4.fontWeight};
  font-size: ${({ theme }) => theme.styles.h4.fontSize[0]}rem;
  line-height: ${({ theme }) => theme.styles.h4.lineHeight[0]};

  ${greaterThan(480)} {
    font-size: ${({ theme }) => theme.styles.h4.fontSize[1]}rem;
    line-height: ${({ theme }) => theme.styles.h4.lineHeight[1]};
  }
`;

export const h5Style = css``;

export const h6Style = css``;

export const introStyle = css``;

export const unbreakableStringStyle = css`
  white-space: nowrap;
  word-break: keep-all;
`;

export const unstyledLinkStyle = css`
  color: inherit;
  text-decoration: inherit;
`;

export const magnifyStyle = css`
  color: ${({ theme }) => theme.colors.primary};

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${34 / 22}rem;
  line-height: ${46 / 34};
`;

export const smallTextStyle = css`
  font-size: 0.5em;
`;

export const titleStyle = css`
  margin-bottom: ${60 / 22}rem;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${22 / 16}rem;
  line-height: ${26 / 22};

  ${greaterThan(480)} {
    ${h1Style}

    font-weight: ${({ theme }) => theme.fontWeights.light};
  }
`;

export const figCaptionStyle = css`
  margin-bottom: ${rem(30, 22)};

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${rem(22, 22)};
  line-height: ${22 / 30};
`;

export const linkStyle = css`
  &[href^='mailto:'],
  &[href^='tel:'] {
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ theme }) => theme.fontWeights.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`;

export const externalLinkStyle = css``;

export const paragraphStyle = css`
  color: ${({ theme }) => theme.styles.p.color};
  font-weight: ${({ theme }) => theme.styles.p.fontWeight};
  font-family: ${({ theme }) => theme.styles.p.fontFamily};
  line-height: ${({ theme }) => theme.styles.p.lineHeight};
`;

export const unorderListStyle = css``;

export const orderListStyle = css``;

export const GlobalStyle = createGlobalStyle`
  :root {
    ${rootStyles};
  }

  html {
    ${htmlStyles};
  }

  body {
    ${bodyStyles};
  }
`;

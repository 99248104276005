/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, langLinks }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },

        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      {langLinks.map((link) => (
        <link
          key={link.href}
          rel='alternate'
          hrefLang={link.langKey}
          href={link.href}
        />
      ))}

      <script
        id='cookieyes'
        type='text/javascript'
        src='https://cdn-cookieyes.com/client_data/c9f6f8135f03afac5470325c/script.js'
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  langLinks: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  langLinks: PropTypes.arrayOf(
    PropTypes.shape({
      langKey: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
};

export default SEO;

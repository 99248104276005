import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyle } from '../../styles/global';

import '@csstools/normalize.css';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, localeLinks }) => {
  return (
    <>
      <GlobalStyle />

      <Header localeLinks={localeLinks} />

      <main>{children}</main>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  localeLinks: PropTypes.arrayOf(PropTypes.shape()),
};

Layout.defaultProps = {
  localeLinks: [],
};

export default Layout;

import React from 'react';

const IconFacebook = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.439 30.449'>
    <path
      d='M36.606 82.319H9.514A1.676 1.676 0 0 0 7.837 84v27.09a1.678 1.678 0 0 0 1.677 1.677H24.1v-11.791h-3.989v-4.587h3.971v-3.4c0-3.93 2.4-6.073 5.9-6.073a31.36 31.36 0 0 1 3.544.182v4.107h-2.43c-1.915 0-2.281.912-2.281 2.241v2.935h4.554l-.6 4.585h-3.931v11.794H36.6a1.676 1.676 0 0 0 1.675-1.677V84a1.676 1.676 0 0 0-1.669-1.681z'
      transform='translate(-7.837 -82.319)'
      fill='#939598'
    />
  </svg>
);

export default IconFacebook;

// vendors
import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from 'react-use';
import colors from '@src/styles/colors';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';

const Tag = styled(Link)`
  display: block;
  margin: 0;
  padding: 0;
`;

const Logo = () => {
  const { pathname } = useLocation();
  const { locale } = useIntl();

  const isHomePage = ['/', '/en'].includes(pathname);

  const filtredLang = locale === 'en' ? '/en' : '/';

  const props = {
    to: isHomePage ? undefined : filtredLang,
    as: isHomePage ? 'h1' : undefined,
  };

  return (
    <Tag {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 257.62 41.15'
        css={css`
          height: 100%;
          overflow: visible;
        `}
      >
        <g fill='#707070'>
          <path d='M118,2.84v3h.87V.1H118l-2.78,3.14L112.39.1h-.87V5.85h.84V1.2l2.78,3.07h0L118,1.19Z' />
          <path d='M125.8,4.33l.9,1.52h.93l-2-3.31L124.15.06h-.81l-3.49,5.79h.89l.91-1.52h4.15ZM122,3.74,123.73.81l1.74,2.93Z' />
          <path d='M129.49,2.84V1.14l4.56,4.35.38.36h.69V.1h-.85V4.7l-.56-.54L129.46.1h-.81V5.85h.84Z' />
          <path d='M139.84,5.94a4.48,4.48,0,0,0,1.74-.32A2.27,2.27,0,0,0,143,3.39V.1h-.86V3.44a1.7,1.7,0,0,1-.6,1.39,2.68,2.68,0,0,1-1.71.51c-1.46,0-2.34-.7-2.34-1.94V.1h-.86V3.44c0,1.63,1.29,2.5,3.18,2.5Z' />
          <path d='M145.45,3.71V3.34h4.14V2.75h-4.14V.7h4.63V.1h-5.5V5.84h.87Z' />
          <path d='M150.62,5.85l.9-1.52h4.17l.9,1.52h.92l-2.59-4.3L154,.06h-.81l-3.48,5.79Zm3-5,1.14,1.92.6,1h-3.49Z' />
          <path d='M161.57,5.94a4.63,4.63,0,0,0,3.06-1l-.57-.41a3.84,3.84,0,0,1-2.46.82c-1.69,0-3-1-3-2.38S159.9.6,161.6.6a4.38,4.38,0,0,1,.5,0,3.89,3.89,0,0,1,1.9.75l.59-.48A4.63,4.63,0,0,0,162.1,0c-.16,0-.31,0-.49,0-2.25,0-3.87,1.34-3.87,3s1.63,3,3.83,3Z' />
          <path d='M167.67,2.84v3h.87V.7h2.57V.1h-6V.7h2.57Z' />
          <path d='M175.3,5.94A4.46,4.46,0,0,0,177,5.62a2.29,2.29,0,0,0,1.46-2.23V.1h-.87V3.44A1.72,1.72,0,0,1,177,4.83a2.68,2.68,0,0,1-1.71.51c-1.47,0-2.34-.7-2.34-1.94V.1h-.86V3.44c0,1.63,1.28,2.5,3.18,2.5Z' />
          <path d='M180.9,3.71v-.1h2.18L184.44,5l.86.87h1.07l-1.93-1.93L184,3.49l.43-.07c1-.23,1.65-.77,1.65-1.61s-.62-1.36-1.65-1.6a5.24,5.24,0,0,0-1.11-.12H180V5.84h.86Zm0-.87V.7h2.36a3.29,3.29,0,0,1,1.17.18,1,1,0,0,1,.79,1,1.09,1.09,0,0,1-.79,1,3,3,0,0,1-1.18.21H180.9Z' />
          <path d='M191.26,5.85H193V5.26h-4.72v-2h4.17V2.65h-4.17v-2H193V.1h-5.53V5.84h3.82Z' />
          <path d='M195.14,3.71v-.1h2.18l2.22,2.23h1.06l-2.35-2.35c1.2-.16,2.08-.72,2.08-1.68S199.25.09,197.56.09h-3.29V5.84h.87Zm0-.87V.7h2.36c1.23,0,1.95.42,1.95,1.14s-.82,1.19-2,1.19h-2.34Z' />
          <path d='M151.64,38v3h.86V35.3h-.87l-2.79,3.14-2.79-3.14h-.87v5.75H146V36.4l2.79,3.07h0l2.79-3.07Z' />
          <path d='M159.47,39.53l.9,1.52h.93l-2-3.31-1.49-2.48H157l-3.49,5.79h.89l.9-1.52ZM155.65,39,157.39,36,159.13,39Z' />
          <path d='M168.78,41.05V35.3h-.84v4.61l-.57-.55-4.25-4.06h-.81v5.75h.84V36.34l4.56,4.35.38.36Z' />
          <path d='M175.84,38.31v.34a1.76,1.76,0,0,1-.6,1.39,2.63,2.63,0,0,1-1.71.5c-1.47,0-2.34-.7-2.34-1.94V35.3h-.86v3.35c0,1.62,1.29,2.5,3.18,2.5a4.46,4.46,0,0,0,1.73-.32,2.29,2.29,0,0,0,1.46-2.23V35.3h-.87Z' />
          <path d='M183.75,35.3h-5.5v5.75h.86v-2.5h4.14V38h-4.14V35.9h4.63Z' />
          <path d='M190.25,41.05h.92l-2.58-4.3-.9-1.49h-.81l-3.49,5.79h.89l.91-1.52h4.16Zm-1.8-2.1h-2.93L187.27,36l1.14,1.92.6,1Z' />
          <path d='M195.26,40.55c-1.68,0-3-1-3-2.38s1.27-2.37,3-2.37l.5,0a4,4,0,0,1,1.91.75l.59-.48a4.65,4.65,0,0,0-2.49-.87c-.17,0-.31,0-.49,0-2.26,0-3.87,1.34-3.87,3s1.63,3,3.83,3a4.63,4.63,0,0,0,3.06-1l-.57-.42A3.86,3.86,0,0,1,195.26,40.55Z' />
          <path d='M201.78,35.3h-3v.6h2.56v5.15h.88V35.9h2.57v-.6Z' />
          <path d='M211.29,38.31v.34a1.73,1.73,0,0,1-.6,1.39,2.61,2.61,0,0,1-1.71.5c-1.46,0-2.34-.7-2.34-1.94V35.3h-.86v3.35c0,1.62,1.29,2.5,3.18,2.5a4.46,4.46,0,0,0,1.73-.32,2.28,2.28,0,0,0,1.46-2.23V35.3h-.86Z' />
          <path d='M219,41.05H220l-1.85-1.85-.5-.5a4,4,0,0,0,.5-.1c.94-.24,1.58-.76,1.58-1.58s-.59-1.34-1.58-1.58A4.7,4.7,0,0,0,217,35.3H213.7v5.75h.86V38.82h2.19l1.42,1.43Zm-4.4-2.82V35.9h2.35a3.19,3.19,0,0,1,1.26.22,1,1,0,0,1,.7.92,1.06,1.06,0,0,1-.7,1,2.9,2.9,0,0,1-1.27.24Z' />
          <path d='M221.19,38.31v2.74h.86V35.3h-.86Z' />
          <path d='M229.38,40.46h-4.72v-2h4.17v-.59h-4.17v-2h4.66V35.3H223.8v5.75h5.58Z' />
          <path d='M236.68,37c0-1-1.08-1.72-2.77-1.72h-3.29v5.75h.87V38.82h2.18l2.22,2.23H237L234.6,38.7c1.2-.16,2.08-.72,2.08-1.68Zm-5.19,1.22V35.9h2.36c1.23,0,2,.43,2,1.14s-.81,1.2-2,1.2Z' />
          <path d='M181.79,14.13h19.13V9.84H174V31.3h7.77V23h16.86V18.7H181.79Z' />
          <path d='M203.43,12.85V31.3h7.77V9.84h-7.77Z' />
          <path d='M218.69,5.85h35.08L257.62.1H222.55Z' />
          <path d='M223.77,9.84H216V31.3h20.7L239.58,27H223.77Z' />
        </g>
        <g fill={colors.turtleGreen}>
          <path d='M14.35,24.86h5.9c5.79,0,10.73-1.38,13-4.06a5.29,5.29,0,0,0,1.3-3.48v-.06a6,6,0,0,0-.15-1.2,5.51,5.51,0,0,0-1.15-2.29c-2-2.42-6.29-3.93-12.23-3.93H6.58V31.3h7.77Zm0-10.76h6c3.88,0,6.3,1.14,6.3,3.25v.06c0,1.84-2.27,3.25-6.15,3.25H14.35Z' />
          <path d='M66.15,31.3h7.63V24.43h6.1l7.57,6.87h9.09l-1.1-1-7.53-6.68c4.2-.95,7.15-2.91,7.53-6,0-.22,0-.44,0-.67v-.06c0-.19,0-.38,0-.57C95,12.28,90.11,9.84,82.16,9.84H66V31.3Zm7.63-17.2H81.5c3.78,0,6.1,1,6.1,3.07v.06c0,1.81-2.17,3-5.95,3H73.78Z' />
          <path d='M106.07,17.39l9.83,9.07h.2L126,17.3v14h7.73V9.84h-8.38l-9.29,9.08-9.28-9.08H98.44V31.3h7.63Z' />
          <path d='M171,31.3h1.61L171,29,157.48,9.69h-7.17L135.17,31.3h7.93l3.22-4.81h14.94l3.23,4.81Zm-21.91-9,4.69-7,4.69,7Z' />
          <path d='M217.73.1H210l-3.85,5.75h7.76Z' />
          <path d='M36.24,28.29v3h26.9V27.13H36.24Z' />
          <path d='M62.88,12.85v-3H36.24V14H62.88Z' />
          <path d='M36.24,19.59v3H60.62V18.41H36.24Z' />
          <path d='M0,41.08H136.54l3.89-5.79H3.89Z' />
        </g>
      </svg>
    </Tag>
  );
};

export default Logo;

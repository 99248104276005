// vendors
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import colors from '@src/styles/colors';

export const List = styled.ul`
  position: absolute;
  top: 100%;
  left: calc(-20px + 0.5em);

  z-index: 1;

  display: block;

  background-color: ${colors.turtleGreen};
  opacity: 0;

  pointer-events: none;
`;

export const ListItem = styled.li`
  :not(:last-child)::after {
    display: block;

    height: 1px;
    margin: 0 20px;

    background-color: ${colors.white};

    content: '';
  }
`;

export const Link = styled(GatsbyLink)`
  display: block;

  padding: 20px;

  color: ${colors.white};
  white-space: nowrap;
  text-decoration: none;

  word-break: normal;

  background-color: ${colors.turtleGreen};

  cursor: pointer;
`;

// vendors
import { hideVisually } from 'polished';
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';

import Button from '@components/Button';

import LangSwitcher from './LangSwitcher';

import { List, ListItem, Wrapper, Link, CTAWrapper } from './Navigation.styles';

const query = graphql`
  query {
    navigationsJson(id: { eq: "main" }) {
      locales {
        items {
          link
          name
        }
        locale
      }
    }
  }
`;

const Navigation = ({ localeLinks }) => {
  const data = useStaticQuery(query);
  const { locale } = useIntl();

  const { items } = data.navigationsJson.locales.find(
    (e) => e.locale === locale
  );

  const splicedItems = [...items];

  const ContactItem = splicedItems.splice(-1, 1)[0];

  return (
    <Wrapper>
      <List
        css={css`
          &::after {
            grid-column: ${splicedItems.length + 1} / span 1;
          }
        `}
      >
        {splicedItems.map((item) => {
          const url = item.link;

          return (
            <ListItem key={item.link}>
              <Link to={url} activeClassName='active' partiallyActive>
                {item.name}
              </Link>

              {/* {item.sub && <SubNavigation items={item.sub} />} */}
            </ListItem>
          );
        })}

        <ListItem
          css={`
            order: 999;
            justify-self: stretch;
          `}
        >
          <CTAWrapper>
            <Button
              tag='link'
              to={ContactItem.link}
              css={`
                width: 100%;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                font-weight: 600;
              `}
            >
              {ContactItem.name}
            </Button>
          </CTAWrapper>
        </ListItem>

        <ListItem>
          <p css={hideVisually}>Langues</p>

          <LangSwitcher localeLinks={localeLinks} />
        </ListItem>
      </List>
    </Wrapper>
  );
};

Navigation.propTypes = {
  localeLinks: PropTypes.arrayOf(PropTypes.shape()),
};

Navigation.defaultProps = {
  localeLinks: [],
};

export default Navigation;

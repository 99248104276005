import React from 'react';

const IconVimeo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.119 26.003'>
    <path
      d='M85.158 109.2c-.048-.013-.1-.025-.149-.035a3.239 3.239 0 0 1-1.569-.8 6.474 6.474 0 0 1-1.22-1.513 13.816 13.816 0 0 1-1.17-2.481c-.106-.293-.172-.6-.253-.9l-2.18-7.992a20.864 20.864 0 0 0-.861-2.569 7.46 7.46 0 0 0-.8-1.5 2.78 2.78 0 0 0-.485-.533 1.275 1.275 0 0 0-.669-.306.467.467 0 0 0-.27.038 6.287 6.287 0 0 0-.563.3c-.51.326-1.013.664-1.521 1l-.111.076-1.417-1.821.083-.073c1.427-1.276 2.849-2.554 4.282-3.822a18.888 18.888 0 0 1 2.569-1.95 7.427 7.427 0 0 1 1.1-.566 3.31 3.31 0 0 1 1.5-.22 3.162 3.162 0 0 1 1.192.313 3.3 3.3 0 0 1 1.228 1.076 6.085 6.085 0 0 1 .823 1.735 12.619 12.619 0 0 1 .409 1.831c.119.725.232 1.45.351 2.175s.248 1.465.374 2.2a36.379 36.379 0 0 0 .985 4.367 9.045 9.045 0 0 0 .6 1.559 2.72 2.72 0 0 0 .543.775.972.972 0 0 0 .366.227.716.716 0 0 0 .548-.061 2.136 2.136 0 0 0 .551-.384 6.974 6.974 0 0 0 .945-1.061 24.355 24.355 0 0 0 2.268-3.691 7.982 7.982 0 0 0 .649-1.708 4 4 0 0 0 .061-1.723 1.626 1.626 0 0 0-.563-1 1.776 1.776 0 0 0-.783-.351 3.779 3.779 0 0 0-1.094-.038 5.816 5.816 0 0 0-1.687.414l-.058.018-.018-.008c.008-.033.013-.068.023-.1a13.115 13.115 0 0 1 1.655-3.531 7.751 7.751 0 0 1 2.478-2.355 7.244 7.244 0 0 1 2.326-.861 9 9 0 0 1 2.2-.136 5.262 5.262 0 0 1 1.553.3 3.593 3.593 0 0 1 2.018 1.8 5.642 5.642 0 0 1 .515 1.624 11.154 11.154 0 0 1 .126 1.715 9.661 9.661 0 0 1-.647 3.486 21.25 21.25 0 0 1-1.463 3.142 42.259 42.259 0 0 1-2.892 4.426c-.947 1.288-1.922 2.556-2.971 3.764a28.225 28.225 0 0 1-3.481 3.448 12.445 12.445 0 0 1-2.511 1.632 6.591 6.591 0 0 1-1.791.586c-.1.018-.207.028-.311.043a.588.588 0 0 0-.091.02z'
      transform='translate(-71.922 -83.199)'
      fill='#939598'
    />
  </svg>
);

export default IconVimeo;

// vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BurgerButton from '@components/BurgerButton';
import Logo from './Logo';
import {
  BurgerWrapper,
  Container,
  LogoWrapper,
  Wrapper,
  NavigationContainer,
  Spacer,
} from './header.styles';
import Navigation from './Navigation';

const Header = ({ localeLinks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container>
        <Wrapper $navOpen={isOpen}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <BurgerWrapper>
            <BurgerButton onClick={handleClick} open={isOpen} />
          </BurgerWrapper>

          <NavigationContainer>
            <Navigation open={isOpen} localeLinks={localeLinks} />
          </NavigationContainer>
        </Wrapper>
      </Container>

      <Spacer />
    </>
  );
};

Header.propTypes = {
  localeLinks: PropTypes.arrayOf(PropTypes.shape()),
};

Header.defaultProps = {
  localeLinks: [],
};

export default Header;

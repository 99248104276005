import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import colors from '@src/styles/colors';

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  :not(:last-child) {
    margin-right: 1em;
  }
`;

export const Link = styled(GatsbyLink)`
  color: inherit;

  font-weight: 600;
  font-size: inherit;
  text-transform: uppercase;
  text-decoration: none;

  word-break: normal;

  &[disabled] {
    color: ${colors.veryLightPink800};

    cursor: auto;
  }
`;

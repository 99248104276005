/* eslint-disable no-underscore-dangle */
import colors from '@src/styles/colors';
import zIndices from '@src/styles/zIndices';
import { greaterThan, lessThan } from '@src/utils/mediaQuery';
import { rem } from 'polished';
import styled from 'styled-components';

import {
  Wrapper as NavigationWrapper,
  List as NavigationList,
} from './Navigation/Navigation.styles';

export const _breakpoint = 1024;

export const Container = styled.header`
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: ${zIndices.sticky};

  ${lessThan(_breakpoint)} {
    pointer-events: none;
  }

  ${greaterThan(_breakpoint)} {
    bottom: initial;

    padding: 0 24px;

    background-color: ${colors.white};

    border-bottom: 2px solid ${colors.paleOliveGreen};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * {
    width: 160px;
  }

  ${lessThan(1024)} {
    height: 64px;
    padding-left: 24px;

    background-color: ${colors.white};

    border-bottom: 2px solid ${colors.paleOliveGreen};

    pointer-events: auto;
  }

  ${greaterThan(_breakpoint)} {
    > * {
      width: 256px;
    }
  }
`;

export const BurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${lessThan(1024)} {
    height: 64px;

    padding-right: 24px;

    background-color: ${colors.white};

    border-bottom: 2px solid ${colors.paleOliveGreen};

    pointer-events: auto;
  }

  ${greaterThan(_breakpoint)} {
    display: none;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;

  ${NavigationWrapper} {
    flex-grow: 1;
  }

  ${lessThan(_breakpoint)} {
    grid-row: 2 / span 1;
    grid-column: span 2;

    align-items: center;
    justify-content: stretch;

    overflow: scroll;

    color: ${colors.white};
    font-size: ${rem(22)};

    background-color: ${colors.charcoal};

    pointer-events: auto;
  }

  ${greaterThan(_breakpoint)} {
    justify-content: flex-end;

    height: 100%;

    color: ${colors.black};
    font-size: ${rem(18, 22)};

    ${NavigationWrapper} {
      grid-auto-flow: column;
      max-width: 953px;
    }

    ${NavigationList}::after {
      align-self: center;
      justify-self: center;
      width: 1px;

      height: 48px;

      background-color: ${colors.paleOliveGreen};

      content: '';
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto ${({ $navOpen }) => ($navOpen ? '1fr' : 0)};
  grid-template-columns: 1fr 1fr;

  ${lessThan(_breakpoint)} {
    height: 100%;
  }

  ${greaterThan(_breakpoint)} {
    grid-gap: 1em;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    max-width: 1408px;
    height: 88px;
    margin: auto;

    background-color: ${colors.white};
  }
`;

export const Spacer = styled.div`
  height: 64px;

  ${greaterThan(_breakpoint)} {
    height: 88px;
  }
`;

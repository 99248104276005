import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import StyledButton, { IconWrapper, TextWrapper } from './Button.styles';

const defaultTags = {
  link: Link,
  href: 'a',
  button: 'button',
};

const Button = ({
  to,
  tag,
  type,
  primary,
  outlined,
  disabled,
  children,
  renderIcon,
  iconFirst,
  hasIconOnly,
  white,
  ...rest
}) => {
  const selectedTag = defaultTags[tag || `button`] || tag;

  const props = {
    to: tag === `link` ? to : undefined,
    href: tag === `href` ? to : undefined,
    type: !tag || tag === `button` ? type || `button` : undefined,
    ...rest,
  };

  return (
    <StyledButton
      as={selectedTag}
      disabled={disabled}
      $primary={primary}
      $outlined={outlined}
      $hasIcon={!!renderIcon}
      $iconFirst={iconFirst}
      $hasIconOnly={hasIconOnly}
      $white={white}
      {...props}
    >
      {renderIcon ? (
        <>
          {!hasIconOnly && <TextWrapper>{children}</TextWrapper>}

          <IconWrapper>{renderIcon}</IconWrapper>
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  tag: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  primary: PropTypes.bool,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  hasIconOnly: PropTypes.bool,
  iconFirst: PropTypes.bool,
  white: PropTypes.bool,
  children: PropTypes.node,
  renderIcon: PropTypes.node,
};

Button.defaultProps = {
  children: undefined,
  tag: undefined,
  to: undefined,
  type: undefined,
  primary: false,
  outlined: false,
  disabled: false,
  renderIcon: undefined,
  iconFirst: false,
  hasIconOnly: false,
  white: false,
};

export default Button;

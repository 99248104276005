// vendors
import styled, { css } from 'styled-components';
import { em, rem } from 'polished';

// utils
import { lessThan } from '@utils/mediaQuery';

// styles
import { easing, speed } from '@styles/animation';

export const IconWrapper = styled.span`
  width: ${em(34, 22)};
  min-width: 30px;
`;

export const TextWrapper = styled.span`
  flex-grow: 1;
`;

export const hasIconOnlyStyle = css`
  min-width: 0;
  padding: ${({ theme }) => theme.spaces.sd3}rem;
`;

export const hasIconStyle = css`
  ${TextWrapper} {
    order: -1;
    margin: 0 ${({ theme }) => theme.spaces.s0}rem 0 0;

    text-align: left;

    ${({ $iconFirst }) =>
      $iconFirst &&
      css`
        margin: 0 0 0 ${({ theme }) => theme.spaces.s0}rem;
        order: 1;

        text-align: right;
      `}
  }
`;

export const outlineFocusHoverStyle = css`
  color: ${({ theme }) => theme.buttons.outlined.hover.color};

  background-color: ${({ theme }) => theme.buttons.outlined.hover.bg};
  border-color: ${({ theme }) => theme.buttons.outlined.hover.borderColor};
`;

export const outlineStyle = css`
  color: ${({ theme }) => theme.buttons.outlined.color};

  background-color: ${({ theme }) => theme.buttons.outlined.bg};

  :focus,
  :hover:not(:disabled) {
    ${outlineFocusHoverStyle}
  }
`;

export const outlinePrimaryFocusStyle = css`
  color: ${({ theme }) => theme.buttons.primary.outlined.hover.color};

  background-color: ${({ theme }) => theme.buttons.primary.outlined.hover.bg};
  border-color: ${({ theme }) =>
    theme.buttons.primary.outlined.hover.borderColor};
`;

export const outlinePrimaryStyle = css`
  color: ${({ theme }) => theme.buttons.primary.outlined.color};

  background-color: ${({ theme }) => theme.buttons.primary.outlined.bg};

  :focus,
  :hover:not(:disabled) {
    ${outlinePrimaryFocusStyle}
  }
`;

export const outlineWhiteStyle = css`
  color: ${({ theme }) => theme.buttons.white.outlined.color};

  background-color: ${({ theme }) => theme.buttons.white.outlined.bg};

  border-color: ${({ theme }) => theme.buttons.white.outlined.borderColor};

  :focus,
  :hover:not(:disabled) {
    color: ${({ theme }) => theme.buttons.white.outlined.hover.color};

    background-color: ${({ theme }) => theme.buttons.white.outlined.hover.bg};

    border-color: ${({ theme }) =>
      theme.buttons.white.outlined.hover.borderColor};
  }
`;

export const primaryStyle = css`
  color: ${({ theme }) => theme.buttons.primary.color};

  background-color: ${({ theme }) => theme.buttons.primary.bg};
  border-color: ${({ theme }) => theme.buttons.primary.borderColor};

  :focus,
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.buttons.primary.hover.bg};
    border-color: ${({ theme }) => theme.buttons.primary.hover.borderColor};
  }

  ${({ $outlined }) => $outlined && outlinePrimaryStyle};
`;

export const whiteStyle = css`
  color: ${({ theme }) => theme.buttons.white.color};

  background-color: ${({ theme }) => theme.buttons.white.bg};
  border-color: ${({ theme }) => theme.buttons.white.borderColor};

  :focus,
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.buttons.white.hover.bg};
    border-color: ${({ theme }) => theme.buttons.white.hover.borderColor};
  }

  ${({ $outlined }) => $outlined && outlineWhiteStyle};
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: ${(props) => props.theme.spaces.s0}rem
    ${(props) => props.theme.spaces.sd1}rem;

  color: ${({ theme }) => theme.buttons.color};

  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  line-height: ${({ theme }) => theme.buttons.lineHeight};
  text-decoration: none;

  background-color: ${({ theme }) => theme.buttons.bg};

  border: ${({ theme }) => theme.buttons.borderThin}px solid
    ${({ theme }) => theme.buttons.borderColor};

  outline: 0;
  cursor: pointer;

    @media (prefers-reduced-motion: no-preference) {
    transition: color ${speed.superfast} ${easing.inQuint},
      border-color ${speed.superfast} ${easing.inQuint},
      background-color ${speed.superfast} ${easing.inQuint};
  }

  :disabled {
    cursor: not-allowed;

    opacity: 0.5;
  }

  :focus {
    outline: ${({ theme }) => theme.buttons.borderThin}px solid;

    outline-color: ${({ theme }) => theme.buttons.outlineColor};

    outline-offset: ${({ theme }) => theme.buttons.borderThin}px;
  }

  :focus,
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.buttons.hover.bg};
    border-color: ${({ theme }) => theme.buttons.hover.borderColor};

    @media (prefers-reduced-motion: no-preference) {
      transition: border-color ${speed.superfast} ${easing.outQuint},
        background-color ${speed.superfast} ${easing.outQuint};
    }
  }

  appearance: none;

  ${({ $outlined }) => $outlined && outlineStyle}

  ${({ $hasIconOnly }) => $hasIconOnly && hasIconOnlyStyle}

  ${({ $hasIcon }) => $hasIcon && hasIconStyle}

  ${({ $primary }) => $primary && primaryStyle}

  ${({ $white }) => $white && whiteStyle}

  ${lessThan(480)} {
    font-size: ${rem(22)};
  }
`;

export default StyledButton;

import React from 'react';

const IconYoutube = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.181 21.355'>
    <path
      d='M72.4 101.018a4.467 4.467 0 0 1-4.453 4.456h-22.27a4.47 4.47 0 0 1-4.456-4.456V88.572a4.469 4.469 0 0 1 4.456-4.453h22.272a4.467 4.467 0 0 1 4.451 4.453zm-18.814-2.41l8.432-4.362-8.432-4.362z'
      transform='translate(-41.221 -84.119)'
      fill='#939598'
    />
  </svg>
);

export default IconYoutube;
